import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useStartupRoute } from 'hooks';

type Paths =
  | '/create-startup-profile'
  | '/startup-agreement'
  | '/startup-pitch-details'
  | '/investor-summary';

export const useRedirectStartupStatus = () => {
  const router = useRouter();
  const paths: Record<string, Paths> = useMemo(
    () => ({
      1: '/create-startup-profile',
      2: '/startup-agreement',
      3: '/startup-pitch-details',
      4: '/investor-summary',
    }),
    [],
  );

  const { user } = useStartupRoute();
  const status = user?.status;
  useEffect(() => {
    if (router.pathname === '/investment-kyc') {
      return;
    }
    if (status === 4 && user?.company?.approved !== 'approved') {
      router.replace('/startup-pitch-details');
      return;
    }
    if (status) {
      if (paths[status.toString()] !== router.pathname) {
        router.replace(paths[status.toString()]);
      }
    }
  }, [status, router, paths, user?.company?.approved]);
};
