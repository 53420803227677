import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useInvestorRoute } from './useInvestorRoute';

type Paths =
  | '/investor-assessment'
  | '/investor-KYC'
  | '/investor-agreement'
  | '/investor-onboarded';

export const useRedirectUserStatus = () => {
  const router = useRouter();
  const paths: Record<string, Paths> = useMemo(
    () => ({
      1: '/investor-assessment',
      2: '/investor-KYC',
      3: '/investor-agreement',
      4: '/investor-onboarded',
    }),
    [],
  );

  const { user } = useInvestorRoute();
  const status = user?.status;
  useEffect(() => {
    if (router.pathname === '/investment-kyc') {
      return;
    }
    if (status) {
      if (paths[status.toString()] !== router.pathname) {
        router.replace(paths[status.toString()]);
      }
    }
  }, [status, router, paths]);
};
