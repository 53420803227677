import { useState } from 'react';
import clsx from 'clsx';
import { toast, ToastContainer } from 'react-toastify';
import { useForm } from 'react-hook-form';
import GoogleImage from 'assets/images/googleImg.png';
import {
  FormGroup,
  FormLabel,
  Grid,
  CircularProgress,
  Box,
  InputAdornment,
  IconButton,
  useTheme,
  Container,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomButton, CustomTextField, MetaTags } from 'components/common';

import Link from 'components/Link';
import OtpInput from 'react-otp-input';

import { ApiPostNoAuth } from 'helpers/Api/ApiData';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import signinImg from 'assets/images/signin-img.svg';
import loginMobileImg from 'assets/images/login.svg';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Auth from 'helpers/auth';

import GoogleLogin from 'react-google-login';
import { useRouter } from 'next/router';
import { useUser } from 'context/user.context';
import { useRedirectAuthorizedPaths } from 'hooks';

export default function Login() {
  const theme = useTheme();
  const useStyles = makeStyles({
    // loginSignUpWrap: {
    //   display: 'flex',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   flex: 1,
    //   '& MuiGrid-item': {
    //     width: '100%',
    //   },
    // },
    // loginSignUpBox: {
    //   height: '100%',
    //   backgroundColor: '#fff',
    //   padding: '30px',
    //   borderRadius: 0,
    //   display: 'flex',
    //   flexWrap: 'wrap',
    //   alignItems: 'center',
    //   // [theme.breakpoints.down('lg')]: {
    //   //     padding: "119px 15% 50px",
    //   // },
    //   // [theme.breakpoints.down('sm')]: {
    //   //     padding: "37% 15% 25%",
    //   // },
    //   // [theme.breakpoints.down('xs')]: {
    //   //     padding: "119px 10% 50px",
    //   // },
    //   '& h2': {
    //     marginBottom: 20,
    //     fontWeight: 500,
    //     fontSize: 20,
    //     lineHeight: 1,
    //     color: '#111111',
    //   },
    //   '& .MuiFormGroup-root': {
    //     marginBottom: 20,
    //   },
    //   '& .MuiFormLabel-root': {
    //     // color: "#000",
    //     fontSize: 14,
    //     color: '#111111',
    //     lineHeight: 1.2,
    //     padding: 0,
    //     [theme.breakpoints.down('xs')]: {
    //       fontSize: 16,
    //     },
    //   },
    //   '& .MuiInputLabel-outlined': {
    //     transform: 'translate(15px, 15px) scale(1)',
    //   },
    //   '& .MuiInputLabel-shrink': {
    //     transform: 'translate(15px, -8px) scale(1)',
    //     backgroundColor: '#fff',
    //     fontSize: 12,
    //     padding: '0 11px',
    //     color: 'rgba(17, 17, 17, 0.5)',
    //   },
    //   '& .MuiInputBase-formControl': {
    //     '& .MuiOutlinedInput-input': {
    //       padding: '12px 15px',
    //       fontSize: 14,
    //       [theme.breakpoints.down('xs')]: {
    //         fontSize: 16,
    //         padding: '15px 15px',
    //       },
    //     },
    //   },
    //   '& .MuiButton-root': {
    //     maxWidth: '250px',
    //     width: '100%',
    //     borderRadius: 40,
    //     margin: '0px auto',
    //     [theme.breakpoints.down(281)]: {
    //       width: 200,
    //     },
    //     '& span': {
    //       fontSize: 20,
    //       lineHeight: 1,
    //       [theme.breakpoints.down(360)]: {
    //         fontSize: 18,
    //       },
    //     },
    //   },
    // },
    // loginSignUpBoxOtp: {
    //   height: '100%',
    //   backgroundColor: '#fff',
    //   padding: '30px',
    //   borderRadius: 0,
    //   display: 'flex',
    //   flexWrap: 'wrap',
    //   alignItems: 'center',
    //   // [theme.breakpoints.down('lg')]: {
    //   //     padding: "119px 15% 0px",
    //   // },
    //   // // [theme.breakpoints.down('xs')]: {
    //   // //     padding: "0",
    //   // // },
    //   // [theme.breakpoints.down('md')]: {
    //   //     padding: "45px 10% 20px",
    //   // },
    //   // [theme.breakpoints.down('xs')]: {
    //   //     padding: "45px 7% 20px",
    //   // },
    //   '& h2': {
    //     marginBottom: 20,
    //     fontWeight: 500,
    //     fontSize: 20,
    //     lineHeight: 1,
    //     color: '#111111',
    //   },
    //   '& .MuiFormGroup-root': {
    //     marginBottom: 20,
    //   },
    //   '& .MuiFormLabel-root': {
    //     // color: "#000",
    //     fontSize: 14,
    //     color: '#111111',
    //     lineHeight: 1.2,
    //     padding: 0,
    //     [theme.breakpoints.down('xs')]: {
    //       fontSize: 16,
    //     },
    //   },
    //   '& .MuiInputLabel-outlined': {
    //     transform: 'translate(15px, 15px) scale(1)',
    //   },
    //   '& .MuiInputLabel-shrink': {
    //     transform: 'translate(15px, -8px) scale(1)',
    //     backgroundColor: '#fff',
    //     fontSize: 12,
    //     padding: '0 11px',
    //     color: 'rgba(17, 17, 17, 0.5)',
    //   },
    //   '& .MuiInputBase-formControl': {
    //     '& .MuiOutlinedInput-input': {
    //       padding: '12px 15px',
    //       fontSize: 14,
    //       [theme.breakpoints.down('xs')]: {
    //         fontSize: 16,
    //         padding: '15px 15px',
    //       },
    //     },
    //   },
    //   '& .MuiButton-root': {
    //     width: '250px',
    //     borderRadius: 40,
    //     margin: '0px auto',
    //     [theme.breakpoints.down(281)]: {
    //       width: 200,
    //     },
    //     '& span': {
    //       fontSize: 20,
    //       lineHeight: 1,
    //       [theme.breakpoints.down(321)]: {
    //         fontSize: 18,
    //       },
    //     },
    //   },
    // },
    // loginSignImg: {
    //   // height: 'calc(100%)',
    //   height: '100%',
    //   display: 'flex',
    //   flexWrap: 'wrap',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    //   background: 'rgba(252, 252, 252, 0.5)',
    //   // paddingTop: '100px',
    //   [theme.breakpoints.down('sm')]: {
    //     display: 'none',
    //   },
    //   '& img': {
    //     width: '90%',
    //   },
    // },
    // forgotLink: {
    //   cursor: 'pointe[[r',
    //   display: 'block',
    //   fontSize: 14,
    //   color: 'rgba(17, 17, 17, 0.7)',
    //   lineHeight: '19px',
    //   marginBottom: '20px',
    //   [theme.breakpoints.down('xs')]: {
    //     fontSize: 16,
    //   },
    //   '&:hover': {
    //     color: 'rgba(17, 17, 17, 1)',
    //   },
    // },
    // googleAccountButton: {
    //   '& span': {
    //     justifyContent: 'flex-start',
    //     fontSize: '18px !important',
    //   },
    //   '& small': {
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     borderRadius: '50%',
    //     backgroundColor: '#fff',
    //     width: '40px',
    //     height: '40px',
    //     padding: '5px',
    //     marginRight: '10px',
    //   },
    // },
    // newRegister: {
    //   textAlign: 'center',
    //   fontSize: 14,
    //   cursor: 'pointer',
    //   fontWeight: 400,
    //   marginTop: '-10px',
    //   [theme.breakpoints.down('xs')]: {
    //     fontSize: 16,
    //   },
    //   '& a': {
    //     color: '#008b5e',
    //     fontWeight: 700,
    //   },
    // },
    // otpInput: {
    //   display: 'flex',
    //   justifyContent: 'space-between',
    //   '& .MuiFormControl-root': {
    //     padding: '0 5px',
    //   },
    //   '& .MuiInputBase-input': {
    //     textAlign: 'center',
    //     padding: 5,
    //   },
    // },
    error: {
      fontSize: 12,
      lineHeight: 1,
      display: 'flex',
      // flexWrap: 'wrap',
      marginTop: 5,
      '& svg': {
        fontSize: 14,
        verticalAlign: 'middle',
        marginRight: 4,
      },
      '& .MuiInputBase-formControl': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#DD6161',
        },
      },
    },
    // VisibleIcon: {
    //   verticalAlign: 'middle',
    //   marginRight: 4,
    //   '& svg': {
    //     fontSize: '1.5rem',
    //   },
    // },
    // mt10: {
    //   marginTop: 10,
    // },
    // BackButton: {
    //   marginBottom: 20,
    //   display: 'block',
    // },
    // otpLabel: {
    //   marginBottom: 15,
    // },
    otpText: {
      width: '16.66666666666667%',
      margin: '0 20px 0 0',
      [theme.breakpoints.down('xs')]: {
        margin: '0 12px 0 0',
      },
      '&:last-child': {
        margin: 0,
      },
      '& input': {
        fontSize: 20,
        textAlign: 'center',
        padding: '10px 17px',
        width: '100% !important',
        border: 'solid 1px #DFDFDF',
        borderRadius: '6px',
        outline: 0,
        [theme.breakpoints.down('lg')]: {
          padding: '10px 14px',
          fontSize: 18,
        },
        [theme.breakpoints.down('xs')]: {
          padding: '10px 10px',
          fontSize: 18,
        },
      },
    },
    // loginSignUpForm: {
    //   // maxWidth: 524,
    //   margin: '0 auto',
    //   // padding:'0px 5% 0px',
    //   '& .MuiSvgIcon-root': {
    //     // marginBottom: '15px',
    //     cursor: 'pointer',
    //   },
    // },
    // [theme.breakpoints.up('sm')]: {
    //   switchDesktop: {
    //     display: 'none',
    //   },
    // },
    // [theme.breakpoints.down('sm')]: {
    //   switchDesktop: {
    //     backgroundColor: '#FFDF8D',
    //     padding: '15px 20px 15px',
    //     display: 'flex',
    //     flexWrap: 'wrap',
    //     alignItems: 'center',
    //     '& span': {
    //       fontSize: 16,
    //       fontWeight: 500,
    //       lineHeight: 1,
    //       marginRight: 'auto',
    //     },
    //     '& .MuiSvgIcon-root': {
    //       background: '#fff',
    //       borderRadius: '50%',
    //     },
    //   },
    // },
    // loginOrOtp: {
    //   textAlign: 'center',
    //   marginBottom: '20px',
    //   color: '#AEAEAE',
    //   '& span': {
    //     textAlign: 'center',
    //     fontSize: '20px',
    //   },
    // },
  });

  const classes = useStyles();
  const history = useRouter();
  const {
    handleSubmit,
    // formState: { errors },
    control,
  } = useForm({ mode: 'all' });

  const [, , setUserData] = useUser();
  const [isNextOpen, openNextStep] = useState(false);
  const [isNextSecondStep, openNextSecondStep] = useState(false);
  const [session_id, setSession_id] = useState('');
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const [phone_no, setPhone_no] = useState('');
  const [loader, setLoader] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  // const [redirectUrl, setRedirectUrl] = useState(null);

  // const [openSocialLoginModal, setOpenSocialLoginModal] = useState(false);
  // const [googleLoginResponse, setGoogleLoginResponse] = useState();
  // const [isGmailSignUp, setIsGmailSignUp] = useState(false);

  // useEffect(() => {
  //   const redirect_url = new URLSearchParams(history.location.search).get(
  //     'redirect_url',
  //   );
  //   setRedirectUrl(redirect_url);
  // }, [history]);

  useRedirectAuthorizedPaths();

  const onSubmit = async (data: any) => {
    setLoader(true);
    try {
      const res: any = await ApiPostNoAuth(`user/login`, { ...data });
      if (res.data.data !== null && res.data.statusCode === 200) {
        setLoader(false);
        const data = res.data.data;
        Auth.setAuthToken(data.token);
        if (data.user.role_id === 1) {
          // Auth.setUserData(res.data?.data?.user);
          setUserData({ user: res.data?.data?.user, isLoading: false });
          // const redirect_url = new URLSearchParams(history.location.search).get(
          //   'redirect_url',
          // );
          // if (redirect_url) {
          //   window.location.replace(`${decodeURIComponent(redirect_url)}`);
          // } else {
          //   history.push('/startups');
          // }
          // isRedirectUrl(history, data.user.role_id, data.user.status, data.user.company);
          // history.push('/investor-assessment')
          // toast.success(res.data.message, { autoClose: 5000, hideProgressBar: true });
          // console.log(history.query.redirect_url);
          if (history.query.redirect_url) {
            history.push(history.query.redirect_url as string);
          } else {
            history.push('/startups');
          }
        } else if (data.user.role_id === 2) {
          // Auth.setUserData(res.data?.data?.user);
          setUserData({ user: res.data?.data?.user, isLoading: false });

          if (history.query.redirect_url) {
            history.push(history.query.redirect_url as string);
          } else {
            history.push('/create-startup-profile');
          }
          // history.push('/create-startup-profile');
          // toast.success(res.data.message, { autoClose: 5000, hideProgressBar: true });
        } else {
          toast.success('Admin cannot login', {
            autoClose: 5000,
            hideProgressBar: true,
          });
        }
      } else {
        toast.error(res.data.message, {
          autoClose: 5000,
          hideProgressBar: true,
        });
        setLoader(false);
      }
    } catch (err: any) {
      console.log('err --------> ', err);
      toast.error(err.message, { autoClose: 5000, hideProgressBar: true });
      setLoader(false);
    }
  };

  const sendOtp = async (data: any) => {
    setLoader(true);
    ApiPostNoAuth(`user/send_otp_sms`, data)
      .then((res: any) => {
        if (res.data.statusCode === 200 && res.data.data !== null) {
          setSession_id(res.data.data.session_id);
          setPhone_no(data.phone_no);
          toast.success(res.data.message, {
            autoClose: 5000,
            hideProgressBar: true,
          });
          openNextSecondStep(true);
          setLoader(false);
        } else {
          toast.error(res.data.message, {
            autoClose: 5000,
            hideProgressBar: true,
          });
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, { autoClose: 5000, hideProgressBar: true });
        setLoader(false);
      });
  };

  const verifyOtp = async (data: any) => {
    let pattern = /[a-zA-Z]/.test(otp);
    if (otp.trim() === '') {
      setOtpError('OTP required');
    } else if (otp.trim().length !== 6) {
      setOtpError('Enter valid OTP');
    } else if (pattern) {
      setOtpError('Only number is allowed');
    } else {
      setLoader(true);
      data = {
        code: otp,
        session_id,
        phone_no,
      };
      ApiPostNoAuth(`user/verify`, data)
        .then((res: any) => {
          if (res.data.statusCode === 200 && res.data.data !== null) {
            const data = res.data.data;
            // Auth.setUserData(data.user);
            setUserData({ user: data?.user, isLoading: false });

            Auth.setAuthToken(data.token);
            if (data.user.role_id === 1) {
              // history.push('/startups');
              if (history.query.redirect_url) {
                history.push(history.query.redirect_url as string);
              } else {
                history.push('/startups');
              }
              // history.push("/investor-assessment")
            } else if (data.user.role_id === 2) {
              if (history.query.redirect_url) {
                history.push(history.query.redirect_url as string);
              } else {
                history.push('/create-startup-profile');
              }
              // history.push('/create-startup-profile');
            } else {
              toast.success('Admin cannot login', {
                autoClose: 5000,
                hideProgressBar: true,
              });
            }
            // toast.success(res.data.message, { autoClose: 5000, hideProgressBar: true });
            setLoader(false);
          } else {
            toast.error(res.data.message, {
              autoClose: 5000,
              hideProgressBar: true,
            });
            setLoader(false);
          }
        })
        .catch((err: any) => {
          console.log('err', err);
          toast.error(err.message, { autoClose: 5000, hideProgressBar: true });
          setLoader(false);
        });
    }
  };

  const handleChangeOtp = (otp: any) => {
    setOtp(otp);
    setOtpError('');
  };

  const handleBack = () => {
    openNextStep(false);
  };
  const handleBackToSendOtp = () => {
    openNextSecondStep(false);
  };
  // const onGoogleSuccess = async (res) => {
  //     console.log('Login Success: currentUser: %j', res.profileObj);
  //     // const res = await ApiPostNoAuth(`user/socialLogin`, { ...res.profileObj, role_id })
  //     // alert(
  //     //   `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
  //     // );
  //     // refreshTokenSetup(res);
  // };

  // const onGoogleFailure = (res) => {
  //     console.log('Login failed: res:', res);
  //     alert(
  //         `Failed to login. 😢 Please ping this to repo owner twitter.com/sivanesh_fiz`
  //     );
  // };
  // const previewModelClose = () => setOpenSocialLoginModal(false);
  // const previewModelOpen = () => setOpenSocialLoginModal(true);
  // const handleSocialModel = () => {
  //   previewModelOpen();
  // };
  const onGoogleSuccess = async (res: any) => {
    console.log('Login Success: currentUser: %j', res.profileObj);
    onSubmitGoogleRegister(res.profileObj);
    // setGoogleLoginResponse(res.profileObj);
    // setIsGmailSignUp(true);
    // const res = await ApiPostNoAuth(`user/socialLogin`, { ...res.profileObj, role_id })
    // alert(
    //   `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
    // );
    // refreshTokenSetup(res);
  };
  const onSubmitGoogleRegister = async (data: any) => {
    try {
      delete data?.googleId;
      const res: any = await ApiPostNoAuth(`user/socialLogin`, {
        ...data,
        type: 'login',
      });
      if (res.data.data !== null && res.data.statusCode === 200) {
        setLoader(false);
        const data = res.data.data;
        Auth.setAuthToken(data.token);
        if (data.user.role_id === 1) {
          // Auth.setUserData(res.data?.data?.user);
          setUserData({ user: res.data?.data?.user, isLoading: false });

          // const redirect_url = new URLSearchParams(history.location.search).get(
          //   'redirect_url',
          // );
          // if (redirect_url) {
          //   window.location.replace(`${decodeURIComponent(redirect_url)}`);
          // } else {
          //   history.push('/startups');
          // }
          // history.push('/startups');
          if (history.query.redirect_url) {
            history.push(history.query.redirect_url as string);
          } else {
            history.push('/startups');
          }
          // isRedirectUrl(history, data.user.role_id, data.user.status, data.user.company);
          // history.push('/investor-assessment')
          // toast.success(res.data.message, { autoClose: 5000, hideProgressBar: true });
        } else if (data.user.role_id === 2) {
          Auth.setUserData(res.data?.data?.user);
          setUserData({ user: res.data?.data?.user, isLoading: false });
          // history.push('/create-startup-profile');
          if (history.query.redirect_url) {
            history.push(history.query.redirect_url as string);
          } else {
            history.push('/create-startup-profile');
          }
          // toast.success(res.data.message, { autoClose: 5000, hideProgressBar: true });
        } else {
          toast.success('Admin cannot login', {
            autoClose: 5000,
            hideProgressBar: true,
          });
        }
      } else {
        toast.error(res.data.message, {
          autoClose: 5000,
          hideProgressBar: true,
        });
        setLoader(false);
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error.message, { autoClose: 5000, hideProgressBar: true });
      setLoader(false);
    }
  };

  return (
    <>
      <MetaTags
        title="Login to POD"
        description="Explore new investing opportunities in early-stage startups or create fundraising campaign for your startup on POD."
        imageUrl={`${process.env.NEXT_PUBLIC_FRONTEND_URL}pod-logo.png`}
        url={process.env.NEXT_PUBLIC_FRONTEND_URL!}
        keywords="POD login"
      />
      <ToastContainer />
      {/* <CommonDescription metaName="login" /> */}
      <Container sx={{ flexGrow: 1, display: 'flex' }} maxWidth="xl">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              // display: 'flex',
              alignItems: 'center',
              '& img': {
                width: '90%',
              },
              display: {
                md: 'flex',
                xs: 'none',
              },
              px: { xs: 0, md: '5%', lg: '10%' },
            }}
          >
            <img
              src={!isNextOpen ? signinImg.src : loginMobileImg.src}
              alt="signUpImage"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ alignSelf: 'center', px: { xs: 0, md: '10%' } }}
          >
            {!isNextOpen ? (
              <Box>
                <Box>
                  <Typography
                    fontSize={20}
                    component="h1"
                    fontWeight={500}
                    mb={2}
                  >
                    Login
                  </Typography>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                      <CustomTextField
                        defaultValue=""
                        type="text"
                        name="email"
                        fullWidth
                        label="Email"
                        variant="outlined"
                        control={control}
                        rules={{
                          required: 'Email is required',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Invalid email address',
                          },
                        }}
                        sx={{
                          mb: 1,
                        }}
                        // className={errors.email && classes.error}
                      />
                      {/* {errors.email && (
                        <Box className={classes.error} color="error.main">
                          <InfoOutlined />
                          {errors.email.message}
                        </Box>
                      )} */}
                    </FormGroup>
                    <FormGroup>
                      <CustomTextField
                        defaultValue=""
                        fullWidth
                        name="password"
                        type={passwordVisible ? 'text' : 'password'}
                        label="Password"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setPasswordVisible(!passwordVisible)
                                }
                              >
                                {passwordVisible ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        control={control}
                        rules={{
                          required: 'Password is required',
                        }}
                        // className={errors.password && classes.error}
                      />
                      {/* {errors.password && (
                        <Box className={classes.error} color="error.main">
                          <InfoOutlined />
                          {errors.password.message}
                        </Box>
                      )} */}
                    </FormGroup>
                    <FormGroup sx={{ display: 'inline' }}>
                      <Link
                        sx={{
                          textDecoration: 'none',
                          color: 'rgba(17, 17, 17, 0.7)',
                          fontSize: 14,
                          '&:hover': {
                            color: '#111111',
                          },
                          display: 'inline',
                        }}
                        // onClick={() => history.push('/forgot-password')}
                        href="/forgot-password"
                      >
                        Forgot Password?
                      </Link>
                    </FormGroup>
                    <FormGroup
                      sx={{ justifyContent: 'center', flexDirection: 'row' }}
                    >
                      {loader ? (
                        <CustomButton
                          // bgColor="#008b5e"
                          // bordercolor="#008b5e"
                          // fontColor="#ffffff"
                          // radius="0"
                          // btnPadding="15"
                          sx={{ fontSize: 20, width: 248, height: 50, mt: 3 }}
                          variant="contained"
                          disableElevation
                        >
                          <CircularProgress color="inherit" size={16} />
                        </CustomButton>
                      ) : (
                        <CustomButton
                          type="submit"
                          sx={{ fontSize: 20, width: 248, height: 50, mt: 3 }}
                          variant="contained"
                          disableElevation
                          // bgColor="#008B5E"
                          // bordercolor="#008B5E"
                          // fontColor="#ffffff"
                          // radius="0"
                          // btnPadding="15"
                        >
                          Login
                        </CustomButton>
                      )}
                    </FormGroup>
                    {/* <Box sx={{ textAlign: 'center' }}>
                      <span>OR</span>
                    </Box> */}
                    <Typography
                      fontSize={20}
                      textAlign="center"
                      color="#AEAEAE"
                      sx={{ my: 2 }}
                    >
                      OR
                    </Typography>
                    <FormGroup
                      sx={{ justifyContent: 'center', flexDirection: 'row' }}
                    >
                      <CustomButton
                        onClick={() => openNextStep(true)}
                        // bgColor="#fff"
                        // bordercolor="#008B5E"
                        // fontColor="#008B5E"
                        // radius="0"
                        // btnPadding="15"
                        variant="outlined"
                        sx={{ fontSize: 20, width: 248, height: 50 }}
                      >
                        Login via Mobile OTP
                      </CustomButton>
                    </FormGroup>
                  </form>
                  <div>
                    <FormGroup
                      sx={{ justifyContent: 'center', flexDirection: 'row' }}
                    >
                      <GoogleLogin
                        clientId={
                          '335830995858-tbi0m6n9fmf8q4nlrr5o062ebg7a8vpq.apps.googleusercontent.com'
                        }
                        render={(renderProps) => (
                          <CustomButton
                            type="submit"
                            sx={{
                              fontSize: 20,
                              width: 248,
                              height: 50,
                              mt: 2,
                              mb: 3,
                              background: '#4285F4',
                              '&:hover': {
                                backgroundColor: '#4285F4',
                              },
                              pl: 0,
                            }}
                            onClick={() => {
                              renderProps.onClick();
                            }}
                            disabled={renderProps.disabled}
                            className="googleSignup"
                          >
                            <Box
                              // className="googleIcon"
                              component="small"
                              sx={{
                                // display: 'flex',
                                alignItems: 'center',
                                backgroundColor: '#fff',
                                borderRadius: 50,
                                height: '40px',
                                width: '40px',
                                mr: 1,
                                '& img': {
                                  width: '80%',
                                  height: '80%',
                                  m: 'auto',
                                },
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              {' '}
                              <img src={GoogleImage.src} alt="" />
                            </Box>
                            Login with Google
                          </CustomButton>
                        )}
                        buttonText="Login with Google"
                        onSuccess={onGoogleSuccess}
                        // onFailure={onGoogleFailure}
                        cookiePolicy={'single_host_origin'}
                        style={{ marginTop: '100px' }}
                        // isSignedIn={true}
                      />
                    </FormGroup>
                  </div>
                  <Box mb={5} textAlign="center">
                    New here?{' '}
                    <Link
                      sx={{ textDecoration: 'none', fontWeight: 500 }}
                      // to={
                      //   redirectUrl
                      //     ? {
                      //         pathname: '/register',
                      //         search: `?redirect_url=${encodeURIComponent(
                      //           redirectUrl,
                      //         )}`,
                      //       }
                      //     : { pathname: '/register' }
                      // }
                      href="/register"
                    >
                      Sign Up
                    </Link>
                  </Box>
                  {/* <CustomButton bgColor="#008b5e" bordercolor="#008b5e" fontColor="#ffffff" radius="0" btnPadding="15" onClick={() => handleSocialModel()}>
                                            GoogleLogin
                                        </CustomButton> */}
                </Box>
              </Box>
            ) : !isNextSecondStep ? (
              <div>
                <div>
                  <KeyboardBackspaceIcon
                    sx={{ cursor: 'pointer' }}
                    onClick={handleBack}
                  />
                  <Typography
                    fontSize={20}
                    component="h1"
                    fontWeight={500}
                    mb={2}
                  >
                    Login
                  </Typography>
                  <form onSubmit={handleSubmit(sendOtp)}>
                    <FormGroup>
                      <CustomTextField
                        fullWidth
                        name="phone_no"
                        type="text"
                        label="Mobile Number"
                        variant="outlined"
                        control={control}
                        rules={{
                          required: 'Phone number is required',
                          pattern: {
                            value: /^[6789]\d{9}$/,
                            message: 'Invalid mobile number',
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <CustomButton
                        disabled={loader}
                        type="submit"
                        variant="contained"
                        sx={{
                          fontSize: 20,
                          height: 50,
                          width: {
                            xs: '100%',
                            md: 200,
                          },
                        }}
                      >
                        {loader ? (
                          <CircularProgress sx={{ color: '#fff' }} size={16} />
                        ) : (
                          'Send OTP'
                        )}
                      </CustomButton>
                    </FormGroup>
                  </form>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  {/* <Link to="/" className={classes.BackButton}><svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.75 7.74998H3.925L8.4625 2.29998C8.67467 2.04471 8.77675 1.71561 8.74628 1.38507C8.7158 1.05454 8.55527 0.749652 8.3 0.537478C8.04473 0.325305 7.71563 0.223228 7.3851 0.253702C7.05456 0.284177 6.74967 0.444708 6.5375 0.699979L0.2875 8.19998C0.245451 8.25963 0.207849 8.3223 0.175 8.38748C0.175 8.44998 0.175 8.48748 0.0875002 8.54998C0.0308421 8.6933 0.0011764 8.84587 0 8.99998C0.0011764 9.15409 0.0308421 9.30665 0.0875002 9.44998C0.0875002 9.51248 0.0874998 9.54998 0.175 9.61248C0.207849 9.67765 0.245451 9.74032 0.2875 9.79998L6.5375 17.3C6.65503 17.4411 6.8022 17.5546 6.96856 17.6323C7.13491 17.7    1 7.31636 17.7503 7.5 17.75C7.79207 17.7505 8.07511 17.6488 8.3 17.4625C8.42657 17.3575 8.5312 17.2287 8.60789 17.0832C8.68458 16.9378 8.73183 16.7787 8.74692 16.6149C8.76202 16.4512 8.74466 16.2861 8.69586 16.1291C8.64705 15.9721 8.56775 15.8263 8.4625 15.7L3.925 10.25H18.75C19.0815 10.25 19.3995 10.1183 19.6339 9.88386C19.8683 9.64944 20 9.3315 20 8.99998C20 8.66846 19.8683 8.35052 19.6339 8.1161C19.3995 7.88167 19.0815 7.74998 18.75 7.74998Z" fill="black" /></svg></Link> */}
                  <KeyboardBackspaceIcon
                    sx={{ cursor: 'pointer' }}
                    onClick={handleBackToSendOtp}
                  />
                  <h2>Login</h2>
                  <form onSubmit={handleSubmit(verifyOtp)}>
                    <FormGroup sx={{ my: 2 }}>
                      <FormLabel sx={{ mb: 2 }}>Enter 6 digit OTP</FormLabel>
                      <OtpInput
                        value={otp}
                        onChange={handleChangeOtp}
                        numInputs={6}
                        // name="code"
                        isInputNum={true}
                        placeholder=""
                        separator={<span></span>}
                        className={clsx(
                          otpError && classes.error,
                          classes.otpText,
                        )}
                      />
                      {otpError && (
                        <Box className={classes.error} color="error.main">
                          <InfoOutlined />
                          {otpError}
                        </Box>
                      )}
                    </FormGroup>
                    <FormGroup>
                      {/* {loader ? (
                        <CustomButton
                        // bgColor="#008b5e"
                        // bordercolor="#008b5e"
                        // fontColor="#ffffff"
                        // radius="0"
                        // btnPadding="15"
                        >
                          <CircularProgress color="inherit" size={16} />
                        </CustomButton>
                      ) : ( */}
                      <CustomButton
                        type="submit"
                        variant="contained"
                        // bgColor="#008b5e"
                        // bordercolor="#008b5e"
                        // fontColor="#ffffff"
                        // radius="0"
                        // btnPadding="15"
                        sx={{
                          fontSize: 20,
                          height: 50,
                          width: '100%',
                          mt: 2,
                        }}
                      >
                        {loader ? (
                          <CircularProgress color="inherit" size={16} />
                        ) : (
                          'Login'
                        )}
                      </CustomButton>
                      {/* )} */}
                    </FormGroup>

                    <Box
                      mt={2}
                      // className={classes.newRegister}
                      sx={{ cursor: 'pointer', textAlign: 'center' }}
                      onClick={() => {
                        sendOtp({ phone_no: phone_no });
                        setOtp('');
                      }}
                    >
                      Resend OTP
                    </Box>
                  </form>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
      {/* <SocialLogin
        openSocialModel={openSocialLoginModal}
        closeSocialModel={previewModelClose}
      /> */}
      {/* <CommonSwitchDashboard/> */}
      {/* <GoogleLogin
                clientId={"285625097264-506jg8hvbej7m6b8vgb1k6lm3o3nhj3c.apps.googleusercontent.com"}
                buttonText="Login With Google"
                onSuccess={onGoogleSuccess}
                onFailure={onGoogleFailure}
                cookiePolicy={'single_host_origin'}
                style={{ marginTop: '100px' }}
                isSignedIn={true}
            /> */}
    </>
  );
}

// import { useUser } from 'context/user.context';
// import { ApiPostNoAuth } from 'helpers/Api/ApiData';
// import Auth from 'helpers/auth';
// import useGuestRoute from 'hooks/useGuestRoute';
// import { useRouter } from 'next/router';
// import { FormEvent, useRef } from 'react';

// const Login = () => {
//   const [, , setUser] = useUser();
//   const emailRef = useRef<HTMLInputElement>(null);
//   const passwordRef = useRef<HTMLInputElement>(null);
//   useGuestRoute();
//   const router = useRouter();

//   const onSubmit = async (e: FormEvent) => {
//     e.preventDefault();
//     // setLoader(true);
//     const email = emailRef.current?.value;
//     const password = passwordRef.current?.value;

//     const data = { email, password };
//     try {
//       const res: any = await ApiPostNoAuth(`user/login`, { ...data });
//       if (res.data.data !== null && res.data.statusCode === 200) {
//         // setLoader(false);
//         const data = res.data.data;
//         // console.log('data', data);
//         Auth.setAuthToken(data.token);
//         setUser({ user: data.user, isLoading: false });

//         if (data.user.role_id === 1) {
//           Auth.setUserData(res.data?.data?.user);
//           // const redirect_url = new URLSearchParams(history.location.search).get(
//           //   'redirect_url',
//           // );
//           // if (redirect_url) {
//           //   window.location.replace(`${decodeURIComponent(redirect_url)}`);
//           // } else {
//           router.push('/startups');
//           // }
//           // isRedirectUrl(history, data.user.role_id, data.user.status, data.user.company);
//           // history.push('/investor-assessment')
//           // toast.success(res.data.message, { autoClose: 5000, hideProgressBar: true });
//         } else if (data.user.role_id === 2) {
//           Auth.setUserData(res.data?.data?.user);
//           router.push('/create-startup-profile');
//           // toast.success(res.data.message, { autoClose: 5000, hideProgressBar: true });
//         } else {
//           // toast.success('Admin cannot login', {
//           //   autoClose: 5000,
//           //   hideProgressBar: true,
//           // });
//         }
//       } else {
//         // toast.error(res.data.message, {
//         //   autoClose: 5000,
//         //   hideProgressBar: true,
//         // });
//         // setLoader(false);
//       }
//     } catch (err) {
//       console.log('err --------> ', err);
//       // toast.error(err.message, { autoClose: 5000, hideProgressBar: true });
//       // setLoader(false);
//     }
//   };

//   return (
//     <form onSubmit={onSubmit}>
//       <label htmlFor="">Email</label>
//       <input ref={emailRef} type="text" name="email" />
//       {/* <CustomTextField
//           defaultValue=""
//           type="text"
//           name="email"
//           fullWidth
//           label="Email"
//           variant="outlined"
//           control={control}
//           rules={{
//             required: 'Email is required',
//             pattern: {
//               value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
//               message: 'Invalid email address',
//             },
//           }}
//           className={errors.email && classes.error}
//         /> */}
//       {/* {errors.email && (
//           <Box className={classes.error} color="error.main">
//             <InfoOutlined />
//             {errors.email.message}
//           </Box>
//         )} */}

//       <label htmlFor="">Password</label>
//       <input ref={passwordRef} type="password" name="password" />

//       {/* <CustomTextField
//           defaultValue=""
//           fullWidth
//           name="password"
//           type={passwordVisible ? 'text' : 'password'}
//           label="Password"
//           variant="outlined"
//           InputProps={{
//             endAdornment: (
//               <InputAdornment position="start">
//                 <IconButton
//                   className={classes.VisibleIcon}
//                   aria-label="toggle password visibility"
//                   onClick={() => setPasswordVisible(!passwordVisible)}
//                 >
//                   {passwordVisible ? <Visibility /> : <VisibilityOff />}
//                 </IconButton>
//               </InputAdornment>
//             ),
//           }}
//           control={control}
//           rules={{
//             required: 'Password is required',
//           }}
//           className={errors.password && classes.error}
//         />
//         {errors.password && (
//           <Box className={classes.error} color="error.main">
//             <InfoOutlined />
//             {errors.password.message}
//           </Box>
//         )} */}

//       {/* <div
//           className={classes.forgotLink}
//           onClick={() => history.push('/forgot-password')}
//         >
//           Forgot Password?
//         </div> */}

//       {/* {loader ? (
//         <CustomButton
//           bgColor="#008b5e"
//           bordercolor="#008b5e"
//           fontColor="#ffffff"
//           radius="0"
//           btnPadding="15"
//         >
//           <CircularProgress color="inherit" size={16} />
//         </CustomButton>
//       ) : ( */}
//       <button type="submit">Login</button>
//       {/* )} */}
//     </form>
//   );
// };

// export default Login;
