// import { useUser } from 'context/user.context';
// import { useRouter } from 'next/router';
// import { useEffect } from 'react';

// export const useInvestorRoute = () => {
//   const [userData] = useUser();
//   const { user, isLoading } = userData;
//   const router = useRouter();

//   useEffect(() => {
//     if (isLoading) {
//     } else {
//       if (!user || user.role_id !== 1) {
//         router.replace('/login');
//       }
//     }
//   }, [router, user, isLoading]);

//   return userData;
// };

import { useUser } from 'context/user.context';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useInvestorRoute = () => {
  const [userData] = useUser();
  const { user, isLoading } = userData;
  const router = useRouter();

  useEffect(() => {
    if (isLoading) {
    } else {
      if (!user || user.role_id !== 1) {
        router.replace(
          `/register?redirect_url=${encodeURIComponent(router.asPath)}`,
        );
      }
    }
  }, [router, user, isLoading]);

  return userData;
};
