import { useUser } from 'context/user.context';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useStartupRoute = () => {
  const [userData] = useUser();
  const { user, isLoading } = userData;
  const router = useRouter();

  useEffect(() => {
    if (isLoading) {
    } else {
      if (!user || user.role_id !== 2) {
        router.replace(`/register`, {
          query: {
            redirect_url: encodeURIComponent(router.asPath),
            userType: '2',
          },
        });
      }
    }
  }, [router, user, isLoading]);

  return userData;
};
